function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"space-between","align-content":"stretch"}},[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(_vm._s(_vm.title))]),_vm._l((_vm.stats),function(ref,i){
var actionIcon = ref.actionIcon;
var actionText = ref.actionText;
var actionLink = ref.actionLink;
var rest = objectWithoutProperties( ref, ["actionIcon", "actionText", "actionLink"] );
var attrs = rest;
return _c('v-col',{key:i,attrs:{"cols":"4","sm":"4","md":"4","lg":"4"}},[_c('material-stat-card',_vm._b({attrs:{"iconSmall":true},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_vm._v(_vm._s(_vm.title))]},proxy:true},{key:"actions",fn:function(){return [_c('v-icon',{staticClass:"mr-1",attrs:{"small":""},domProps:{"textContent":_vm._s(actionIcon)}}),_c('div',{staticClass:"text-truncate"},[_c('v-btn',{attrs:{"text":"","to":actionLink}},[_vm._v(_vm._s(actionText))])],1)]},proxy:true}],null,true)},'material-stat-card',attrs,false))],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }
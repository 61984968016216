<template>
  <v-row justify="space-between" align-content="stretch">
    <v-col cols="12">{{ title }}</v-col>
    <v-col
      v-for="({ actionIcon, actionText, actionLink, ...attrs }, i) in stats"
      :key="i"
      cols="4"
      sm="4"
      md="4"
      lg="4"
    >
      <material-stat-card v-bind="attrs" :iconSmall="true">
        <template #subtitle>{{ title }}</template>
        <template #actions>
          <v-icon class="mr-1" small v-text="actionIcon" />
          <div class="text-truncate">
            <v-btn text :to="actionLink">{{ actionText }}</v-btn>
          </div>
        </template>
      </material-stat-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "StatsTopCard",
  props: {
    stats: { type: Array },
    title: {
      type: String,
      default: "title text...",
    },
  },
};
</script>
